import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import {Input} from '@progress/kendo-react-inputs';
import Back from './Back';
import Spinner from './Spinner';
import {filterBy} from "@progress/kendo-data-query";

function Projects(props) {
    sessionStorage.setItem('domain', atob(props.match.params.domain));
    const userId = atob(props.match.params.userId);
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [filter, setFilter] = useState([
        {
            logic: 'or',
            filters: [
                {field: 'projectId', operator: 'contains', value: ''},
                {field: 'projectName', operator: 'contains', value: ''}
            ]
        }
    ]);
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + `/task/survey/get-projects`, {userId: userId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId]);

    const locationsCell = (props) => {
        return(
            <td>
                <Link to={{pathname: '/locations', state: {userId: userId, projectId: props.dataItem.projectId}}}>
                    <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.locations}</span>
                </Link>
            </td>
        );
    }

    const searchHandler = (e) => {
        setFilter([{
                logic: 'or',
                filters: [
                    {field: 'projectId', operator: 'contains', value: e.target.value},
                    {field: 'projectName', operator: 'contains', value: e.target.value}
                ]
            }]);
    }

    return (
        <div>
            <Input className={'full-space'}
                   placeholder={'Buscar'}
                   onChange={searchHandler}/>
            <Grid data={filterBy(data, filter)}
                  filter={filter}
                  style={{ height: '420px' }}>
                <GridColumn title={'Id'} field={'projectId'}/>
                <GridColumn title={'Proyecto'} field={'projectName'}/>
                <GridColumn title={'Fecha de inicio'} field={'startDate'}/>
                <GridColumn title={'Fecha de fin'} field={'endDate'}/>
                <GridColumn title={'Ubicaciones'} cell={locationsCell}/>
            </Grid>
            <Back/>
            {loader && <Spinner/>}
        </div>
    )
}

export default Projects;