import React, {useEffect, useState} from 'react';
import Back from './Back';
import axios from "axios";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import {Input} from "@progress/kendo-react-inputs";
import {filterBy} from "@progress/kendo-data-query";

function Tasks(props) {
    let userId = props.location.state.userId;
    let projectId = props.location.state.projectId;
    let locationId = props.location.state.locationId;
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [filter, setFilter] = useState([
        {
            logic: 'or',
            filters: [
                {field: 'assignmentId', operator: 'contains', value: ''},
                {field: 'upaxer', operator: 'contains', value: ''},
                {field: 'taskName', operator: 'contains', value: ''}
            ]
        }
    ]);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + `/task/survey/get-surveys`, {userId: userId, projectId: projectId, locationId: locationId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId, projectId, locationId]);

    const taskNameCell = (props) => {
        return(
            <td>
                <span style={{color: '#FF995B', cursor: 'pointer'}} onClick={() => goToSurvey(props.dataItem)}>{props.dataItem.taskName}</span>
            </td>
        );
    }

    const answerCell = (props) => {
        return(
            <td style={{textAlign: 'center'}}>
                <span className={'k-icon k-i-pencil'} style={{color: '#FF995B', cursor: 'pointer'}} onClick={() => goToSurvey(props.dataItem)}/>
            </td>
        );
    }

    const goToSurvey = (data) => {
        let parameters = [
            {key: "action", value: "get-upaxer-surveys"},
            {key: "surveyId", value: data.surveyId},
            {key: "taskLocationId", value: data.taskLocationId}
        ];
        let domain = sessionStorage.getItem('domain')
        let idx = 0, param = null, url = domain + "/editor/Encuesta?";
        for (idx in parameters) {
            param = parameters[idx];
            url += param.key + "=" + window.btoa(param.value) + "&";
        }
        url = url.substring(0, url.lastIndexOf("&"));
        window.open(url);
    }

    const searchHandler = (e) => {
        setFilter([{
            logic: 'or',
            filters: [
                {field: 'assignmentId', operator: 'contains', value: e.target.value},
                {field: 'upaxer', operator: 'contains', value: e.target.value},
                {field: 'taskName', operator: 'contains', value: e.target.value}
            ]
        }]);
    }

    return(
        <div>
            <Input className={'full-space'}
                   placeholder={'Buscar'}
                   onChange={searchHandler}/>
            <Grid data={filterBy(data, filter)}
                  filter={filter}
                  style={{ height: '420px' }}>
                <GridColumn title={'Id Asignación'} field={'assignmentId'}/>
                <GridColumn title={'PAX'} field={'upaxer'}/>
                <GridColumn title={'Tarea'} cell={taskNameCell}/>
                <GridColumn title={'Descripción'} field={'taskDescription'}/>
                <GridColumn title={'Contestar'} cell={answerCell}/>
            </Grid>
            <Back/>
            {loader && <Spinner/>}
        </div>
    )
}

export default Tasks;