import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Projects from './components/Projects';
import Locations from './components/Locations';
import Tasks from './components/Tasks';

function App() {
    return(
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path={'/projects/:userId/:domain'} component={Projects}/>
                    <Route path={'/locations'} component={Locations}/>
                    <Route path={'/tasks'} component={Tasks}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;