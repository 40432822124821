import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Back from './Back';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import {Input} from "@progress/kendo-react-inputs";
import {filterBy} from "@progress/kendo-data-query";

function Locations(props) {
    const userId = props.location.state.userId;
    const projectId = props.location.state.projectId;
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [filter, setFilter] = useState([
        {
            logic: 'or',
            filters: [
                {field: 'locationId', operator: 'contains', value: ''},
                {field: 'locationName', operator: 'contains', value: ''}
            ]
        }
    ]);

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + `/task/survey/get-locations`, {userId: userId, projectId: projectId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId, projectId]);

    const tasksCell = (props) => {
        return(
            <td>
                <Link to={{pathname: '/tasks', state: {userId: userId, projectId: projectId, locationId: props.dataItem.locationId}}}>
                    <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.tasks}</span>
                </Link>
            </td>
        );
    }

    const searchHandler = (e) => {
        setFilter([{
            logic: 'or',
            filters: [
                {field: 'locationId', operator: 'contains', value: e.target.value},
                {field: 'locationName', operator: 'contains', value: e.target.value}
            ]
        }]);
    }

    return(
        <div>
            <Input className={'full-space'}
                   placeholder={'Buscar'}
                   onChange={searchHandler}/>
            <Grid data={filterBy(data, filter)}
                  filter={filter}
                  style={{ height: '420px' }}>
                <GridColumn title={'Id'} field={'locationId'}/>
                <GridColumn title={'Ubicación'} field={'locationName'}/>
                <GridColumn title={'Dirección'} field={'address'}/>
                <GridColumn title={'Longitud'} field={'longitude'}/>
                <GridColumn title={'Latitud'} field={'latitude'}/>
                <GridColumn title={'Tareas'} cell={tasksCell}/>
            </Grid>
            <Back/>
            {loader && <Spinner/>}
        </div>
    )
}

export default Locations;